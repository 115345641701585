var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"dropdown",class:`simple-dropdown ${_vm.isOpen ? 'simple-dropdown--open' : ''} ${_vm.readonly ? 'simple-dropdown--readonly' : ''} simple-dropdown--${_vm.position}`},[(!_vm.dotsBtn)?_c('div',{staticClass:"simple-dropdown__button body-text color--dark-purple",class:{ 'simple-dropdown__button--clearable': _vm.clearable && _vm.selectedOptionValue },on:{"click":function($event){_vm.openOnArrow ? _vm.$emit('btnClick') : _vm.toggleDropdown()}}},[_vm._t("optionButton",function(){return [(_vm.selectedOptionValue && _vm.selectedOptionValue.optionLogo)?_c('img',{staticClass:"simple-dropdown__logo",attrs:{"src":_vm.selectedOptionValue.optionLogo,"alt":"logo"}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.buttonText ? _vm.buttonText : _vm.selectedOptionValue ? _vm.selectedOptionValue.shortText || _vm.selectedOptionValue.text || _vm.selectedOptionValue.name : ""))]),(_vm.showArrow)?_c('i',{staticClass:"el-icon-arrow-down color--dark-purple",on:{"click":function($event){_vm.openOnArrow ? _vm.toggleDropdown($event) : null}}}):_vm._e(),(_vm.clearable ? _vm.selectedOption : false)?_c('i',{staticClass:"el-icon-close color--dark-purple",on:{"click":_vm.resetOption}}):_vm._e()]})],2):_vm._e(),(_vm.dotsBtn)?_c('div',{staticClass:"simple-dropdown__button simple-dropdown__button--dots",on:{"click":_vm.toggleDropdown}}):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticClass:"simple-dropdown__options",style:({
      top: _vm.offsetTop ? _vm.offsetTop + 'px' : 'auto',
      left: _vm.offsetLeft ? _vm.offsetLeft + 'px' : 'auto',
      minWidth: _vm.minWidth ? _vm.minWidth : null,
    })},_vm._l((_vm.options),function(option){return _c('div',{key:option.id,staticClass:"simple-dropdown__option body-text",class:`${
        _vm.selectedOptionValue && _vm.selectedOptionValue.id === option.id
          ? 'simple-dropdown__option--active'
          : ''
      } ${option.class}`,on:{"click":function($event){return _vm.selectOption(option)}}},[(option.optionLogo)?_c('img',{staticClass:"simple-dropdown__logo",attrs:{"src":option.optionLogo,"alt":"logo"}}):_vm._e(),(option.color)?_c('div',{staticClass:"simple-dropdown__option-dot",style:({ 'background-color': option.color })}):_vm._e(),(option.icon)?_c('img',{staticClass:"simple-dropdown__icon",attrs:{"src":option.icon,"alt":"logo"}}):_vm._e(),_vm._v(" "+_vm._s(option.text || option.name || option.title)+" "),(option.tooltipText)?_c('BaseTooltip',{staticClass:"m_l_auto",attrs:{"tooltip-text":option.tooltipText}}):_vm._e()],1)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }