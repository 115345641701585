import Vue from 'vue';
import axios from 'axios';

window.setCompanyName = (companyName) => {
  window.companyName = companyName;
  Vue.prototype.$companyName = window.companyName;
  document.documentElement.style.setProperty('--company-logo', `var(--${window.companyName}-logo)`);
  axios.defaults.headers.common['X-Product'] = companyName.toLowerCase();
};

window.whiteLabelCompany = 'renova';
window.setCompanyName('Checkme');

// Additional temporary property to set what layout to use for Admin Section
window.adminLayout = 'RenovaDashboardLayout';
