/* eslint-disable no-shadow,no-param-reassign */
import axios from 'axios';
import i18n from '@/i18n';
import {
  CUP_CLIENT_LIST_REQUEST,
  CUP_CLIENT_LIST_SUCCESS,
  CUP_CLIENT_ADD_REQUEST,
  CUP_CLIENT_UPDATE_REQUEST,
  CUP_CLIENT_DELETE_REQUEST,
  CUP_FILES_LIST_GET,
  CUP_FILES_LIST_DOWNLOAD_ALL,
  CUP_SET_COMPANY,
  CUP_USERS_ADD_DOWNLOAD_EXAMPLE,
  CUP_ADD_USERS_FROM_FILE,
  CUP_REMOVE_USERS_FROM_FILE,
  CUP_USERS_REMOVE_DOWNLOAD_EXAMPLE,
  CUP_CLIENT_REQUEST_LOADING,
  CUP_TICKETS_LIST_GET,
  CUP_TICKET_DETAILS_GET,
  CUP_CLIENT_LIST_FILTERS_CHANGE,
  CUP_UPLOAD_FILE,
  CUP_CLIENT_SUGGEST_LIST, CUP_AVAILABLE_DATES_REQUEST,
} from '../actions/hr-user-page';

function NewFilters() {
  this.page = 0;
  this.limit = 100000;
  this.type = null;
  this.searchQuery = '';
}

const state = {
  corporateData: {
    clients: [],
    clientsFilters: new NewFilters(),
    clientsSuggestList: [],
    filesList: [],
    ticketsList: [],
    ticketDetails: null,
    selectedCompany: null,
    haveNewTickets: false,
    loading: false,
    requestLoading: false,
  },
  // Allowed dates for adding new users
  addNewUsersAllowedDates: [],
  // Allowed dates for removing users
  removeUsersAllowedDates: [],
};

const getters = {
  corporateData: () => state.corporateData,
  addNewUsersAllowedDates: () => state.addNewUsersAllowedDates,
  removeUsersAllowedDates: () => state.removeUsersAllowedDates,
};

console.log('state.corporateData.selectedCompany', state.corporateData);
console.log('window.location.hostname', window.location.hostname);

const actions = {
  [CUP_CLIENT_LIST_REQUEST]({ commit, state }) {
    commit(CUP_CLIENT_LIST_REQUEST);
    return axios.post(
      '/company/employee/list',
      state.corporateData.clientsFilters,
      {
        headers: {
          'X-Domain-ID': state.corporateData.selectedCompany.domainId, //
          'X-Locale': i18n.locale,
        },
      },
    )
      .then((resp) => {
        commit(CUP_CLIENT_LIST_SUCCESS, resp.data);
        return Promise.resolve();
      })
      .catch((err) => Promise.reject(err.response.data));
  },
  [CUP_CLIENT_LIST_FILTERS_CHANGE]({ commit, dispatch }, filters) {
    commit(CUP_CLIENT_LIST_FILTERS_CHANGE, filters);
    dispatch(CUP_CLIENT_LIST_REQUEST);
  },
  [CUP_CLIENT_SUGGEST_LIST]({ commit }, searchString) {
    return axios.post(
      '/company/employee/suggest/list',
      { limit: 10, searchString },
      {
        headers: {
          'X-Domain-ID': state.corporateData.selectedCompany.domainId,
          'X-Locale': i18n.locale,
        },
      },
    )
      .then((resp) => {
        commit(CUP_CLIENT_SUGGEST_LIST, resp.data);
        return resp.data;
      })
      .catch((err) => Promise.reject(err.response.data));
  },

  [CUP_TICKETS_LIST_GET]({ commit, state }) {
    return axios.post(
      '/company/ticket/list?page=0&limit=10000',
      {},
      {
        headers: {
          'X-Domain-ID': state.corporateData.selectedCompany.domainId,
          'X-Locale': i18n.locale,
        },
      },
    )
      .then((resp) => {
        commit(CUP_TICKETS_LIST_GET, resp.data);
        return Promise.resolve();
      })
      .catch((err) => Promise.reject(err.response.data));
  },
  [CUP_TICKET_DETAILS_GET]({ commit, state }, ticketId) {
    commit(CUP_TICKET_DETAILS_GET);
    return axios.post(
      '/company/ticket/detail',
      ticketId,
      {
        headers: {
          'X-Domain-ID': state.corporateData.selectedCompany.domainId,
          'X-Locale': i18n.locale,
        },
      },
    )
      .then((resp) => {
        commit(CUP_TICKET_DETAILS_GET, resp.data);
        return Promise.resolve();
      })
      .catch((err) => Promise.reject(err.response.data));
  },
  [CUP_CLIENT_ADD_REQUEST]({ commit, dispatch, state }, user) {
    commit(CUP_CLIENT_REQUEST_LOADING);
    return axios.post(
      '/company/employee/add',
      user,
      {
        headers: {
          'X-Domain-ID': state.corporateData.selectedCompany.domainId,
          'X-Locale': i18n.locale,
        },
      },
    )
      .then((resp) => {
        commit(CUP_CLIENT_REQUEST_LOADING);
        dispatch(CUP_TICKETS_LIST_GET);
        return Promise.resolve();
      })
      .catch((err) => {
        commit(CUP_CLIENT_REQUEST_LOADING);
        return Promise.reject(err.response.data);
      });
  },
  [CUP_CLIENT_UPDATE_REQUEST]({ commit, dispatch, state }, user) {
    commit(CUP_CLIENT_REQUEST_LOADING);
    return axios.post(
      '/company/employee/update',
      user,
      {
        headers: {
          'X-Domain-ID': state.corporateData.selectedCompany.domainId,
          'X-Locale': i18n.locale,
        },
      },
    )
      .then((resp) => {
        commit(CUP_CLIENT_REQUEST_LOADING);
        dispatch(CUP_TICKETS_LIST_GET);
        return Promise.resolve();
      })
      .catch((err) => {
        commit(CUP_CLIENT_REQUEST_LOADING);
        return Promise.reject(err.response.data);
      });
  },
  [CUP_CLIENT_DELETE_REQUEST]({ commit, dispatch, state }, request) {
    commit(CUP_CLIENT_REQUEST_LOADING);
    return axios.post(
      '/company/employee/remove',
      request,
      {
        headers: {
          'X-Domain-ID': state.corporateData.selectedCompany.domainId,
          'X-Locale': i18n.locale,
        },
      },
    )
      .then((resp) => {
        commit(CUP_CLIENT_REQUEST_LOADING);
        dispatch(CUP_TICKETS_LIST_GET);
        dispatch(CUP_CLIENT_LIST_REQUEST);
        return Promise.resolve();
      })
      .catch((err) => {
        commit(CUP_CLIENT_REQUEST_LOADING);
        return Promise.reject(err.response.data);
      });
  },
  [CUP_FILES_LIST_GET]({ commit, state }) {
    commit(CUP_FILES_LIST_GET);
    return axios.get(
      '/company/files',
      {
        headers: {
          'X-Domain-ID': state.corporateData.selectedCompany.domainId,
          'X-Locale': i18n.locale,
        },
      },
    )
      .then((resp) => {
        commit(CUP_FILES_LIST_GET, resp.data);
        return Promise.resolve();
      })
      .catch((err) => Promise.reject(err.response.data));
  },

  [CUP_FILES_LIST_DOWNLOAD_ALL]({ state }) {
    return axios.get(
      '/company/files/download-all',
      {
        headers: {
          'X-Domain-ID': state.corporateData.selectedCompany.domainId,
          'X-Locale': i18n.locale,
        },
        responseType: 'arraybuffer',
      },
    )
      .then((resp) => resp.data)
      .catch((err) => Promise.reject(err.response.data));
  },
  [CUP_USERS_ADD_DOWNLOAD_EXAMPLE]({ state }) {
    return axios.get(
      '/company/employee/template/bundle-add-file',
      {
        headers: {
          'X-Domain-ID': state.corporateData.selectedCompany.domainId,
          'X-Locale': i18n.locale,
        },
      },
    )
      .then((resp) => resp.data)
      .catch((err) => Promise.reject(err.response.data));
  },
  [CUP_USERS_REMOVE_DOWNLOAD_EXAMPLE]({ state }) {
    return axios.get(
      '/company/employee/template/bundle-remove-file',
      {
        headers: {
          'X-Domain-ID': state.corporateData.selectedCompany.domainId,
          'X-Locale': i18n.locale,
        },
      },
    )
      .then((resp) => resp.data)
      .catch((err) => Promise.reject(err.response.data));
  },

  [CUP_UPLOAD_FILE]({ commit }, file) {
    const formData = new FormData();
    formData.append('file', file);
    return axios.post(
      '/company/files/upload',
      formData,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      },
    )
      .then((resp) => resp.data)
      .catch((err) => Promise.reject(err.response));
  },

  [CUP_ADD_USERS_FROM_FILE]({ commit, dispatch, state }, file) {
    commit(CUP_CLIENT_REQUEST_LOADING);
    const formData = new FormData();
    formData.append('file', file);
    return axios.post(
      '/company/files/upload',
      formData,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      },
    )
      .then((resp) => axios.post(
        '/company/employee/add-with-file',
        {
          fileId: resp.data.id,
        },
        {
          headers: {
            'X-Domain-ID': state.corporateData.selectedCompany.domainId,
            'X-Locale': i18n.locale,
          },
        },
      )
        .then((resp) => {
          commit(CUP_CLIENT_REQUEST_LOADING);
          dispatch(CUP_TICKETS_LIST_GET);
          return resp.data;
        })
        .catch((err) => {
          commit(CUP_CLIENT_REQUEST_LOADING);
          return Promise.reject(err.response);
        })).catch((err) => {
        commit(CUP_CLIENT_REQUEST_LOADING);
        return Promise.reject(err.response);
      });
  },
  [CUP_REMOVE_USERS_FROM_FILE]({ commit, dispatch, state }, file) {
    commit(CUP_CLIENT_REQUEST_LOADING);
    const formData = new FormData();
    formData.append('file', file);
    return axios.post(
      '/company/files/upload',
      formData,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
        },
      },
    )
      .then((resp) => axios.post(
        '/company/employee/remove-with-file',
        {
          fileId: resp.data.id,
        },
        {
          headers: {
            'X-Domain-ID': state.corporateData.selectedCompany.domainId,
            'X-Locale': i18n.locale,
          },
        },
      )
        .then((resp) => {
          commit(CUP_CLIENT_REQUEST_LOADING);
          dispatch(CUP_TICKETS_LIST_GET);
          return resp.data;
        })
        .catch((err) => {
          commit(CUP_CLIENT_REQUEST_LOADING);
          return Promise.reject(err.response);
        })).catch((err) => {
        commit(CUP_CLIENT_REQUEST_LOADING);
        return Promise.reject(err.response);
      });
  },

  /**
   * Get available dates for adding or removing employees
   * @param commit
   * @param state
   * @param {string} type COMPANY_EMPLOYEE_ADD or COMPANY_EMPLOYEE_REMOVE
   * @param {number} deadlineInDays number of days to calculate available dates
   * @returns {Promise<void>}
   */
  [CUP_AVAILABLE_DATES_REQUEST]({ commit, state }, type, deadlineInDays = 60) {
    return axios.post(
      '/company/ticket/available-date/list',
      { deadlineInDays, type },
      {
        headers: {
          'X-Domain-ID': state.corporateData.selectedCompany.domainId,
          'X-Locale': i18n.locale,
        },
      },
    )
      .then((resp) => {
        commit(CUP_AVAILABLE_DATES_REQUEST, { type, availableDates: resp.data.availableDates });
      });
  },
};

const mutations = {
  [CUP_CLIENT_LIST_REQUEST]: (state) => {
    state.corporateData.loading = true;
  },
  [CUP_CLIENT_LIST_SUCCESS]: (state, resp) => {
    state.corporateData.clients = resp.items;
    state.corporateData.loading = false;
  },
  [CUP_CLIENT_LIST_FILTERS_CHANGE]: (state, filters) => {
    state.corporateData.clientsFilters = filters;
  },
  [CUP_CLIENT_SUGGEST_LIST]: (state, resp) => {
    state.corporateData.clientsSuggestList = resp.items;
  },

  [CUP_TICKETS_LIST_GET]: (state, resp) => {
    state.corporateData.ticketsList = resp.items;
    state.corporateData.haveNewTickets = resp.items.some((item) => item.status.id === 'TODO');
  },
  [CUP_TICKET_DETAILS_GET]: (state, resp) => {
    state.corporateData.ticketDetails = resp || null;
  },
  [CUP_CLIENT_REQUEST_LOADING]: (state) => {
    state.corporateData.requestLoading = !state.corporateData.requestLoading;
  },
  [CUP_FILES_LIST_GET]: (state, resp) => {
    state.corporateData.filesList = resp || [];
  },
  [CUP_SET_COMPANY]: (state, company) => {
    state.corporateData.selectedCompany = company;
  },
  /**
   * Set available dates for adding or removing insured clients
   * @param state
   * @param {string} type COMPANY_EMPLOYEE_ADD or COMPANY_EMPLOYEE_REMOVE
   * @param {Array<string>} availableDates array of available dates
   */
  [CUP_AVAILABLE_DATES_REQUEST]: (state, { type, availableDates }) => {
    const stateKeyMapping = {
      COMPANY_EMPLOYEE_ADD: 'addNewUsersAllowedDates',
      COMPANY_EMPLOYEE_REMOVE: 'removeUsersAllowedDates',
    };
    state[stateKeyMapping[type]] = availableDates;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
