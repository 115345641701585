import Vue from 'vue';
import axios from 'axios';
import './companyNameSetting';

import ElementUI from 'element-ui';

import wysiwyg from 'vue-wysiwyg';
import VueTour from 'vue-tour';
import VueTheMask from 'vue-the-mask';

import VueSlickCarousel from 'vue-slick-carousel';

import VueChatScroll from 'vue-chat-scroll';
import vueCustomScrollbar from 'vue-custom-scrollbar';

import vueHeadful from 'vue-headful';

import PortalVue from 'portal-vue';
import App from './App';
import router from './router';
import store from './store';
import 'element-ui/lib/theme-chalk/index.css';
import 'vue-custom-scrollbar/dist/vueScrollbar.css';
import 'vue-slick-carousel/dist/vue-slick-carousel.css';
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
import 'vue-wysiwyg/dist/vueWysiwyg.css';

import '@/assets/scss/main.scss';

import '@/services/locale';
import '@/services/filters';
import i18n from './i18n';

require('vue-tour/dist/vue-tour.css');

Vue.use(VueTheMask);
Vue.use(VueChatScroll);
Vue.component('vueCustomScrollbar', vueCustomScrollbar);
Vue.component('VueSlickCarousel', VueSlickCarousel);
Vue.component('vue-headful', vueHeadful);
Vue.use(VueTour);
Vue.use(PortalVue);

Vue.use(ElementUI);
Vue.use(wysiwyg, {
  hideModules: {
    image: true,
    justifyLeft: true,
    justifyCenter: true,
    justifyRight: true,
    headings: true,
    table: true,
    code: true,
    link: true,
  },
});

const eventBus = new Vue(); // для тура
export default eventBus;

Vue.config.productionTip = false;

//  на $mobile.show завязана логика в лендингах
Vue.prototype.$mobile = {
  show: window.innerWidth < 992,
};

const checkMobile = function () {
  Vue.prototype.$mobile.show = window.innerWidth < 992;
};

window.addEventListener('resize', checkMobile);

if (localStorage.getItem('ins-token')) {
  axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('ins-token')}`;
}

window.isDev = window.location.hostname === 'dev.checkme.tech' || window.location.hostname.includes('localhost');

window.WebsocketUrl = 'wss://ws.checkme.tech/connection/websocket';
// Hubspot meeting url
window.HubspotMeetingUrl = window.isDev ? 'https://meetings.hubspot.com/anastasia-kotlyarova' : 'https://meetings.hubspot.com/ileana8/demo-con-illeana';
/**
 * Determine API URL based on hostname
 * for dev and staging environments use dev.checkme.tech
 * for all other hosts
 * @returns string
 */
const getApiURL = () => 'https://checkme.tech/api';
axios.defaults.baseURL = getApiURL();

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
