<template>
  <form v-if="phoneCodes.selectedCountry" class="l-phone-form phone-form__container"
        @submit.prevent="sendRequest">
    <div class="l-phone-form__block">
      <div class="l-phone-form__input-block" :class="{'l-phone-form__error': error}">
        <div class="l-phone-form__input-country">
          <el-popover
            v-model="countryVisible"
            class="auth-country"
            popper-class="auth-phone__country-popper"
            placement="bottom-start"
            width="240px"
            trigger="click"
          >
            <div slot="reference" class="l-phone-form__select__trigger">
              <img :class="rotate" class="l-phone-form__select__trigger-triangle"
                   src="/img/misc/triangle.svg" alt="">

              <img class="l-phone-form__select__trigger-country"
                   :src="phoneCodes.selectedCountry.flagIconWeb" alt="">

              <p class="text_grey text_16">
                {{ phoneCodes.selectedCountry.phoneCode }}
              </p>
            </div>

            <div v-for="item in phoneCodes.codes" :key="item.id"
                 class="l-phone-form__country__popper-menu-el text_15 text_dark"
                 @click="selectCountry(item)">
              <img :src="item.flagIconWeb" alt="">

              <p class="l-phone-form__select__option-country text_16 text_dark">
                {{ $t(`countries.titles.${item.phoneRegion}`) }}
              </p>

              <p class="text_16 text_grey">
                {{ item.phoneCode }}
              </p>
            </div>
          </el-popover>
        </div>

        <input v-if="phoneCodes.selectedCountry" ref="phone" v-model="request.phone"
               v-mask="phoneCodes.selectedCountry.phoneMobileMask"
               class="text_grey" :placeholder="$t('placeholders.phoneRepresentative')">
      </div>
    </div>
    <div class="l-phone-form__block">
      <div class="l-phone-form__input-block" :class="{'l-phone-form__error': emailError}">
        <input
          ref="phone"
          v-model="request.email"
          class="text_grey"
          placeholder="Email">
      </div>
    </div>
    <el-button v-loading="loading" class="l-button-big l-button-dark b-r_10 block"
               :disabled="loading" @click="sendRequest">
      {{ buttonTitle }}
    </el-button>
  </form>
</template>

<script>
import masker from 'vue-the-mask/src/masker';
import { tokens } from 'vue-the-mask';
import { mapGetters } from 'vuex';
import { SET_PHONE_CODE } from '@/store/actions/dictionary';
import RequestsStore from '@/stores/requests';

function Request(country, requestType) {
  this.phone = '';
  this.country = country;
  this.requestType = requestType;
}

/* eslint-disable no-param-reassign */
export default {
  name: 'l-phone-form',
  props: {
    buttonTitle: {
      type: String,
    },
    requestType: {
      type: String,
      default: 'PHONE',
    },

  },
  data() {
    return {
      loading: false,
      countryVisible: false,
      selectedCountry: null,
      request: null,
      error: false,
      emailError: false,
    };
  },
  watch: {
    'phoneCodes.selectedCountry': function () { // необходимо чтобы привести в соответствие форму в тайтле и в модалке
      this.request.country = this.phoneCodes.selectedCountry.phoneRegion;
    },
  },
  computed: {
    ...mapGetters(['phoneCodes']),
    rotate() {
      return {
        rotate: this.countryVisible,
      };
    },
  },
  created() {
    this.setDefault();
  },
  methods: {
    setDefault() {
      this.request = new Request(this.phoneCodes.selectedCountry.phoneRegion, this.requestType);
    },
    selectCountry(item) {
      this.$store.commit(SET_PHONE_CODE, item);
      this.request.phone = '';
      this.countryVisible = false;

      this.$refs.phone.focus();
    },
    unmask(maskedValue, mask) {
      return masker(maskedValue, mask, false, tokens);
    },
    validateEmail(email) {
      const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
      return emailRegex.test(email);
    },
    sendRequest() {
      const request = JSON.parse(JSON.stringify(this.request));
      request.phone = this.phoneCodes.selectedCountry.phoneCode + this.request.phone;

      this.error = this.unmask(this.request.phone, this.phoneCodes.selectedCountry.phoneMobileMask).length < this.phoneCodes.selectedCountry.phoneLength;
      this.emailError = !this.validateEmail(this.request.email);
      if (!this.error && !this.emailError) {
        this.loading = true;

        RequestsStore.methods.dmsRequest(request).then(() => {
          this.loading = false;

          this.setDefault();

          this.$emit('update:dialogVisibleForm', false);

          this.$message({
            message: `${this.$t('adminTitles.sent')}`,
            duration: 4000,
            center: true,
            customClass: 'l-request__message l-request__message--success',
          });
        })
          .catch(() => {
            this.loading = false;

            this.$message({
              message: `${this.$t('adminTitles.someError')}`,
              duration: 5000,
              center: true,
              customClass: 'l-request__message l-request__message--error',
            });
          });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.rotate {
  transform: rotate(180deg);
}

.phone-form__container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.l-phone-form__block{
  max-width: 100%;
}
.l-phone-form__input-block{
  max-width: 100%;
}
</style>
