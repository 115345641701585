<template>
  <div>
    <div class="a-mwe__controls">
      <el-dropdown v-if="showActions('heading')" placement="bottom-start" trigger="click" @command="handleHeadingCommand">
          <span class="el-dropdown-link text_12 cp">
            {{heading.label}} <i class="el-icon-arrow-down el-icon--right" />
          </span>

        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :style="item.style" v-for="item in headings" :key="item.value" :command="item.value">
            {{item.label}}
          </el-dropdown-item>
        </el-dropdown-menu>

      </el-dropdown>

      <button v-if="showActions('bold')" @click="applyBold" class="button" :class="{'a-mwe__button-active': activeCommand.bold}">
        <svg width="20" height="20">
          <use xlink:href="/img/svg/editor.svg#bold"/>
        </svg>
      </button>

      <button v-if="showActions('italic')"  @click="applyItalic" class="button" :class="{'a-mwe__button-active': activeCommand.italic}">
        <svg width="20" height="20">
          <use xlink:href="/img/svg/editor.svg#italic"/>
        </svg>
      </button>

      <button v-if="showActions('underline')" @click="applyUnderline" class="button" :class="{'a-mwe__button-active': activeCommand.underline}">
        <svg width="20" height="20">
          <use xlink:href="/img/svg/editor.svg#underline"/>
        </svg>
      </button>

      <button v-if="showActions('strike')" @click="applyStrikeThrough" class="button" :class="{'a-mwe__button-active': activeCommand.strikeThrough}">
        <svg>
          <use xlink:href="/img/svg/editor.svg#strike"/>
        </svg>
      </button>

      <button v-if="showActions('blockquote')" @click="applyBlockquote" class="blockquote">
        <svg width="20" height="20">
          <use xlink:href="/img/svg/editor.svg#blockquote"/>
        </svg>
      </button>

      <button v-if="showActions('ul')" @click="applyUl" class="button">
        <svg width="20" height="20">
          <use xlink:href="/img/svg/editor.svg#ul"/>
        </svg>
      </button>

      <button v-if="showActions('ol')" @click="applyOl" class="button">
        <svg width="20" height="20">
          <use xlink:href="/img/svg/editor.svg#ol"/>
        </svg>
      </button>

      <el-popover
        v-if="!rangeContainsA && showActions('link')"
        v-model="linkDialogVisible"
        placement="top"
        width="350"
        trigger="manual">

        <el-form label-position="top" label-width="100px">
          <el-form-item label="URL">
            <el-input
              v-model="link"
              :placeholder="urlOrigin"
            />
          </el-form-item>

          <div style="width: fit-content; margin-left: auto">
            <el-button class="mt_12" size="mini" @click="linkDialogVisible = false">{{ $t('adminTitles.cancel') }}</el-button>
            <el-button class="mt_12" size="mini" type="success" @click="createLink">{{ $t('adminTitles.Insert') }}</el-button>
          </div>
        </el-form>

        <button slot="reference" class="button" @click="linkDialogVisible = !linkDialogVisible">
          <svg width="20" height="20">
            <use xlink:href="/img/svg/editor.svg#link"/>
          </svg>
        </button>
      </el-popover>

      <button v-else-if="showActions('link')" @click="applyUnlink" class="button">
        <svg width="20" height="20">
          <use xlink:href="/img/svg/editor.svg#unlink"/>
        </svg>
      </button>

      <button  v-if="showActions('attachment')" @click="$emit('selectAttachment')" class="button">
        <svg width="16" height="16">
          <use xlink:href="/img/svg/editor.svg#attachment"/>
        </svg>
      </button>

      <mwe-emoji-picker v-if="showActions('emoji')" @addEmoji="addEmoji" />
      <!--      <button @click="undo" class="button">
              <svg width="20" height="20">
                <use xlink:href="/img/svg/editor.svg#undo"/>
              </svg>
            </button>
            <button @click="redo" class="button">
              <svg width="20" height="20">
                <use xlink:href="/img/svg/editor.svg#redo"/>
              </svg>
            </button>-->
    </div>

    <mwe-balloon-menu
      v-if="showBalloon"
      :savedRange="savedRange"
      :activeCommand="activeCommand"
      :rangeContainsA="rangeContainsA"
      @applyBold="applyBold"
      @applyItalic="applyItalic"
      @applyUnderline="applyUnderline"
      @applyStrikeThrough="applyStrikeThrough"
    />
  </div>
</template>

<script>
import MweBalloonMenu from '@/components/editor/parts/mwe-balloon-menu';
import MweEmojiPicker from '@/components/editor/parts/mwe-emoji-picker';

export default {
  name: 'mwe-controls',
  components: { MweBalloonMenu, MweEmojiPicker },
  props: ['editor', 'showBalloon', 'hideActions'],
  data() {
    return {
      resetCommandsList: ['bold', 'italic', 'formatBlock', 'em', 'del', 'strikeThrough'],
      headings: {
        p: {
          value: 'p',
          label: this.$t('editor.p'),
          style: {
            'font-size': '12px',
          },
        },
        h1: {
          value: 'h1',
          label: this.$t('editor.h1'),
          style: {
            'font-size': '16px',
            'font-weight': 'bold',
          },
        },
        h3: {
          value: 'h3',
          label: this.$t('editor.h3'),
          style: {
            'font-size': '14px',
            'font-weight': 'bold',
          },
        },
        h5: {
          value: 'h5',
          label: this.$t('editor.h5'),
          style: {
            'font-size': '12px',
            'font-weight': 'bold',
          },
        },
      },
      heading: {
        value: 'p',
        label: this.$t('editor.p'),
        style: {
          'font-size': '12px',
        },
      },
      selection: null,
      savedRange: null,
      rangeContainsA: false,
      linkDialogVisible: false,
      link: '',
      activeCommand: {
        bold: false,
        italic: false,
        underline: false,
        strikeThrough: false,
      },
    };
  },
  computed: {
    urlOrigin() {
      return window.location.origin;
    },
  },
  mounted() {
    document.execCommand('defaultParagraphSeparator', false, 'p');
    this.editor.addEventListener('paste', this.handlePaste);
  },
  methods: {
    createLink() {
      this.restoreSelection();
      document.execCommand('createLink', false, this.link);
      this.linkDialogVisible = false;
      this.link = '';
      window.getSelection().anchorNode.parentElement.target = '_blank';
      this.restoreSelection();
    },
    applyUnlink() {
      this.restoreSelection();
      this.savedRange.selectNodeContents(this.savedRange.commonAncestorContainer);
      document.execCommand('unlink');
      window.getSelection().collapseToEnd();
    },
    applyBold() {
      this.restoreSelection();
      document.execCommand('bold');
      this.saveSelection();
    },
    applyItalic() {
      this.restoreSelection();
      document.execCommand('italic');
      this.saveSelection();
    },
    applyUnderline() {
      this.restoreSelection();
      document.execCommand('underline');
      this.saveSelection();
    },
    applyStrikeThrough() {
      this.restoreSelection();
      document.execCommand('strikeThrough');
      this.saveSelection();
    },
    applyBlockquote() {
      this.restoreSelection();
      document.execCommand('formatBlock', false, 'BLOCKQUOTE');
    },
    applyUl() {
      document.execCommand('insertUnorderedList');
    },
    applyOl() {
      document.execCommand('insertOrderedList');
    },
    undo() {
      document.execCommand('undo');
    },
    redo() {
      document.execCommand('redo');
    },
    showActions(action) {
      return !this.hideActions.includes(action);
    },
    addEmoji(emoji) {
      this.restoreSelection();
      document.execCommand('insertHTML', false, emoji);
    },
    pasteNewLine() {
      this.restoreSelection();
      document.execCommand('insertHTML', false, '<p><br></p>');
    },
    handleHeadingCommand(command) {
      this.heading = this.headings[command];
      this.restoreSelection();
      document.execCommand('formatBlock', false, command);
    },
    handlePaste(e) {
      let clipboardData = null;
      let pastedData = null;

      e.stopPropagation();
      e.preventDefault();

      clipboardData = e.clipboardData || window.clipboardData;
      pastedData = clipboardData.getData('Text');

      if (pastedData?.match(/http(?:[:/?&=a-zA-Z0-9_\-.#]*)/g)) {
        document.execCommand('createLink', false, pastedData?.match(/http(?:[:/?&=a-zA-Z0-9_\-.#]*)/g));
        window.getSelection().anchorNode.parentElement.target = '_blank';
      } else {
        document.execCommand('insertText', false, pastedData);
      }

      window.getSelection().collapseToEnd();
    },
    resetCommands() { // после нажатия enter
      this.resetCommandsList.forEach((item) => {
        if (item === 'formatBlock' && document.queryCommandState(item)) {
          document.execCommand('formatBlock', false, '<p>');
        } else if (document.queryCommandState(item)) {
          document.execCommand(item);
        }

        this.heading = this.headings.p;
      });
    },
    saveSelection() {
      if (window.getSelection && window.getSelection().containsNode(this.editor, true)) {
        this.savedRange = window.getSelection().getRangeAt(0);
        this.checkRangeContainsFormat();
      } else if (document.selection) {
        this.savedRange = document.selection.createRange();
        this.checkRangeContainsFormat();
      }
    },
    restoreSelection() {
      this.editor.focus();

      if (this.savedRange !== null) {
        if (window.getSelection) {
          this.selection = window.getSelection();
          if (this.selection.rangeCount > 0) {
            this.selection.removeAllRanges();
          }
          this.selection.addRange(this.savedRange);
        } else if (document.createRange) {
          window.getSelection().addRange(this.savedRange);
        } else if (document.selection) {
          this.savedRange.select();
        }
      }
    },
    placeCaretAtEndOfSubstring() {
      this.editor.focus();
      if (typeof window.getSelection !== 'undefined' && typeof document.createRange !== 'undefined') {
        this.savedRange.selectNodeContents(this.editor);
        this.savedRange.collapse(false);
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(this.savedRange);
      } else if (typeof document.body.createTextRange !== 'undefined') {
        const textRange = document.body.createTextRange();
        textRange.moveToElementText(this.editor);
        textRange.collapse(false);
        textRange.select();
      }
    },
    checkRangeContainsFormat() {
      this.rangeContainsA = this.savedRange?.commonAncestorContainer.parentElement.nodeName === 'A'
        || Array.from(this.savedRange?.commonAncestorContainer?.childNodes)?.some((item) => item.nodeName === 'A');

      const { localName } = this.savedRange.commonAncestorContainer.parentElement;

      if (localName === 'h1' || localName === 'h3' || localName === 'h5') {
        this.heading = this.headings[localName];
      } else {
        this.heading = this.headings.p;
      }

      Object.keys(this.activeCommand).forEach((key) => {
        this.activeCommand[key] = document.queryCommandState(key);
      });
    },
  },
  destroyed() {
    document.removeEventListener('paste', this.handlePaste);
  },
};
</script>

<style scoped>
.a-mwe__controls {
    padding: 8px 0;
    box-sizing: border-box;
    line-height: 24px;
/* Auto layout */
    display: flex;
    align-items: flex-start;
    padding: 12px 16px;
    gap: 8px;

/* Solid/White */
    background: #FFFFFF;
    border-width: 1px 1px 0px 1px;
    border-style: solid;
    border-color: #69879C79;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
    border-radius: 8px 8px 0px 0px;
    margin: 0;
/* Inside auto layout */
    border-bottom: solid 1px var(--solid-lavender-grey);
  }
</style>
