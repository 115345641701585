<template>
  <div>
    <div v-if="showContent" class="admin-main-container">
      <side-menu/>
      <slot/>
      <demo-greeting/>

      <!--    <demo-greeting />
          <demo-hint-button />

                <v-tour name="tour" :options="options" :steps="steps">
                  <template slot-scope="tour">
                    <transition name="fade">
                      <v-step
                        v-if="tour.steps[tour.currentStep]"
                        :key="tour.currentStep"
                        :step="tour.steps[tour.currentStep]"
                        :previous-step="tour.previousStep"
                        :next-step="tour.nextStep"
                        :stop="tour.stop"
                        :skip="tour.skip"
                        :is-first="tour.isFirst"
                        :is-last="tour.isLast"
                        :labels="tour.labels"
                      >
                        <template v-if="false">
                          <div slot="actions">
                            <button @click="tour.previousStep" class="v-step__button">Назад</button>
                            <button @click="tour.stop" class="v-step__button">Спасибо</button>
                          </div>
                        </template>
                      </v-step>
                    </transition>
                  </template>
                </v-tour>-->
    </div>

    <el-skeleton style="padding: 16px" :rows="30" v-else animated/>
  </div>
</template>

<script>
import axios from 'axios';
import { AUTH_LOGOUT } from '@/store/actions/auth';
import SideMenu from '@/views/Admin/CommonParts/admin-side-menu';
import DemoGreeting from '@/views/Admin/TourParts/demo-greeting';
import DemoHintButton from '@/views/Admin/TourParts/demo-hint-button';
import { tourSteps } from '@/mixins/tour/tourSteps';
import { USER_REQUEST } from '@/store/actions/user';
import { mapGetters } from 'vuex';
import { TICKET_LIST_FILTERS_REQUEST, TICKET_LIST_REQUEST } from '@/store/actions/ticketList';
import { GET_DICTIONARY } from '@/store/actions/dictionary';

export default {
  name: 'AdminLayout',
  mixins: [tourSteps],
  data() {
    return {
      showContent: false,
      getTicketsInterval: null,
    };
  },
  components: {DemoGreeting, SideMenu},
  created() {
    if (!localStorage.getItem('notify-sound')) localStorage.setItem('notify-sound', JSON.stringify(true));

    this.$store.dispatch(USER_REQUEST).then(() => {
      this.setProvider(this.user);
      if (!this.user.domains?.length) {
        this.$store.dispatch(AUTH_LOGOUT).then(() => {
          this.$router.push('/');
        });
      } else if (!this.user.domains.some((item) => item.type === 'INSURANCE_ADMIN_WORKSPACE')) {
        this.$router.push('/');
      } else {
        this.$store.dispatch(GET_DICTIONARY);

        this.$store.dispatch(TICKET_LIST_FILTERS_REQUEST);

        this.showContent = true;
      }
    });

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response.status === 401) {
          // Exception for 401 error from openai, will ignore it
          if (!error.request.responseURL.contains('openai')) {
            this.$store.dispatch(AUTH_LOGOUT).then(() => {
              this.$router.push('/login');
            });
          }
        }

        return Promise.reject(error);
      },
    );
  },
  mounted() {
    this.getTicketsInterval = setInterval(() => {
      this.$store.dispatch(TICKET_LIST_REQUEST).then((resp) => {
        this.newTicketsNotify(resp.totalCount);
      });
    }, 10000);
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    newTicketsNotify(totalCount) {
      const tickets = JSON.parse(localStorage.getItem(`tickets.${this.user.id}`));
      if (!tickets || totalCount > tickets?.count) {
        localStorage.setItem(
          `tickets.${this.user.id}`,
          JSON.stringify({count: totalCount}),
        );
        if (tickets && JSON.parse(localStorage.getItem('notify-sound'))) {
          const audio = new Audio('/sounds/new-ticket.mp3');
          audio.oncanplaythrough = () => {
            const playedPromise = audio.play();
            if (playedPromise) {
              playedPromise.catch((e) => {
                console.log(e.name);
              });
            }
          };
        }
      }
    },
    setProvider(user) {
      const providers = user.domains.filter((domain) => domain.type === 'INSURANCE_ADMIN_WORKSPACE').map((domain) => domain.insuranceCompany);
      const savedProviderId = localStorage.getItem(`${user.id}_insuranceProvider`);
      if (savedProviderId && providers.some((provider) => provider.id === savedProviderId)) {
        axios.defaults.headers.common['X-Insurance-Provider-ID'] = savedProviderId;
      } else {
        localStorage.setItem(`${user.id}_insuranceProvider`, providers[0].id);
        axios.defaults.headers.common['X-Insurance-Provider-ID'] = providers[0].id;
        window.location.reload();
      }
    },
  },
  destroyed() {
    clearInterval(this.getTicketsInterval);
  },
};
</script>

<style scoped>

</style>
