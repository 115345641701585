var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showBanner)?_c('div',{staticClass:"cookie-banner"},[_c('h2',{staticClass:"cookie-banner__title",class:{
      'cookie-banner__title--old': _vm.adminLayout,
      'cookie-banner__title--new': !_vm.adminLayout,
    }},[_vm._v(" "+_vm._s(_vm.$t('adminTitles.ThisWebsiteUsesCookies'))+" ")]),_c('p',{class:{
      'text_16 text_grey': _vm.adminLayout,
      'cookie-banner__description': !_vm.adminLayout,
    },domProps:{"innerHTML":_vm._s(_vm.$t('adminTitles.CookieDescription'))}}),_c('div',{staticClass:"flex"},[(_vm.adminLayout)?_c('el-button',{staticClass:"mt_24",attrs:{"type":"primary"},on:{"click":_vm.acceptAll}},[_vm._v(" "+_vm._s(_vm.$t('adminTitles.CookieBtn'))+" ")]):_vm._e(),(!_vm.adminLayout)?_c('BaseButton',{staticClass:"mt_24",on:{"click":_vm.acceptAll}},[_vm._v(" "+_vm._s(_vm.$t('adminTitles.CookieBtn'))+" ")]):_vm._e()],1)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }